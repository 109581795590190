import React, { useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button
} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { styles } from '../../styles/MeetingDetails';
import { normalizeDate } from '../../../../utils/normalizeDate';

interface MeetingDetailsProps {
  month: string;
  year: string;
  meetings: Array<{
    type: string;
    category: string;
    readable_date: string;
    description: Array<{ text: string }>;
  }>;
  selectedIndex?: number;
  onBack: () => void;
  sumbmission: string | number;
  setHighlightedMeeting: (highlight: { month: string; year: string; idx: number }) => void;
}

const MeetingDetails: React.FC<MeetingDetailsProps> = ({
  month,
  year,
  meetings,
  selectedIndex,
  onBack,
  sumbmission,
  setHighlightedMeeting
}) => {
  const [expanded, setExpanded] = useState<string | false>(
    selectedIndex !== undefined && selectedIndex !== null ? `panel${selectedIndex}` : false
  );

  const handleAccordionChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      if (panel) {
        const idx = parseInt(panel.replace('panel', ''), 10);
        setHighlightedMeeting({ month, year, idx });
      }
    };

  return (
    <>
      <Typography sx={styles.meetingHeader}>
        Meeting(s): {month} {year}
      </Typography>
      <CloseIcon onClick={onBack} sx={styles.closeIcon} />

      {meetings.map((meeting, idx) => (
        <Accordion
          key={idx}
          expanded={expanded === `panel${idx}`}
          onChange={handleAccordionChange(`panel${idx}`)}
          sx={styles.accordion}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon sx={styles.arrowIcon} />}
            sx={{
              padding: 0,
              '& .MuiAccordionSummary-content.Mui-expanded': {
                margin:0
              },
              '& .MuiAccordionSummary-content':{
                margin:0
              }
            }}>
            <Box margin={0}>
              <Typography sx={styles.typography}>
                Type: <span style={styles.typographyValue}>{meeting.type}</span>
              </Typography>
              <Typography sx={styles.typography}>
                Category: <span style={styles.typographyValue}>{meeting.category}</span>
              </Typography>
              <Typography sx={styles.typography}>
                Date:{' '}
                <span style={styles.typographyValue}>{normalizeDate(meeting?.readable_date)}</span>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{padding:0}}>
            <Divider sx={{mt:1,mb:1,color:'gray.300'}} />
            <Typography sx={styles.descriptionHeader}>Description</Typography>
            <ul>
              {meeting.description.map((desc, descIdx) => (
                <li key={descIdx}>
                  <Typography sx={styles.typographyValue}>{desc.text}</Typography>
                </li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}

      <Divider sx={styles.divider} />
      <Box display='flex' justifyContent='start'>
        <Button
          variant='text'
          onClick={onBack}
          sx={styles.backButton}
          startIcon={<ArrowLeftIcon sx={styles.arrowIcon} />}>
          <Typography sx={styles.submissionText}>{`Submission (${sumbmission})`}</Typography>
        </Button>
      </Box>
    </>
  );
};

export default React.memo(MeetingDetails);
