import { GRAPH_ENTITIES, MEETING_TEXT_MAP, meetingDataConfig } from './const';
import { groupBy, isEmpty } from 'lodash';

// Utility function to get selected options
const getSelectedOptions = (selectedOptions: any[]) => {
  return {
    selectedTypes: selectedOptions.map((option) => option.type),
    selectedCategories: selectedOptions.map((option) => option.category),
  };
};

// Utility function to filter meetings based on types and categories
const filterMeetings = (meetings: any[], selectedTypes: string[], selectedCategories: string[]) => {
  return meetings.filter(
    (meeting) => selectedTypes.includes(meeting.type) && selectedCategories.includes(meeting.category)
  );
};

export const getUniqueCategoriesAndTypes = (meetingsArray: any[]) => {
  const uniqueMeetingsMap: Record<string, any> = {};

  meetingsArray.forEach((meeting) => {
    const uniqueKey = `${meeting.category}-${meeting.type}`;
    if (!uniqueMeetingsMap[uniqueKey]) {
      uniqueMeetingsMap[uniqueKey] = {
        category: meeting.category,
        type: meeting.type,
        uuid: meeting.uuid,
        selected: true,
      };
    }
  });

  return Object.values(uniqueMeetingsMap);
};

export const getFilteredMeetings = (meetingData: any[], selectedOptions: any[]) => {
  const { selectedTypes, selectedCategories } = getSelectedOptions(selectedOptions);
  return filterMeetings(meetingData, selectedTypes, selectedCategories);
};

export const extractMeetings = (responseData: any, source: string = 'us') => {
  const meetingsArray: any[] = [];
  const submissionFlags = responseData?.overlay_data[source]?.submission_flags || [];

  submissionFlags.forEach((submissionFlag: any) => {
    const { meetings, submission } = submissionFlag;

    Object.entries(meetings).forEach(([year, months]:any) => {
      Object.entries(months).forEach(([month, meetingsList]:any) => {
        meetingsList.forEach((meeting: any) => {
          meetingsArray.push({
            ...meeting,
            submission,
          });
        });
      });
    });
  });

  return meetingsArray;
};

export const filterMeetingsByTypeAndCategory = (meetingsData: any, selectedOptions_: any) => {
  const filteredMeetings: Record<string, any> = {};
  const selectedOptions = selectedOptions_.filter((c: any) => c.selected);
  const { selectedTypes, selectedCategories } = getSelectedOptions(selectedOptions);

  Object.entries(meetingsData || {}).forEach(([year, months]:any) => {
    Object.entries(months).forEach(([month, meetingsList]:any) => {
      const filteredMonthMeetings = filterMeetings(meetingsList, selectedTypes, selectedCategories);
      if (filteredMonthMeetings.length > 0) {
        if (!filteredMeetings[year]) {
          filteredMeetings[year] = {};
        }
        filteredMeetings[year][month] = filteredMonthMeetings;
      }
    });
  });

  return filteredMeetings;
};

export const showMeeting = (meeting: any, selectedOptions: any) => {
  const { selectedTypes, selectedCategories } = getSelectedOptions(selectedOptions);
  return selectedTypes.includes(meeting.type) && selectedCategories.includes(meeting.category);
};

export const getMeetingMarkerText = (meetingData: any) => {
  const mapKey = (meetingData.type === 'Type B' || meetingData.type === 'Type B (EOP)') 
    ? meetingData.category 
    : meetingData.type;

  return MEETING_TEXT_MAP[mapKey];
};

export const handleTrialReviewBarMeetings = (barGraphData: any[], currentSelectedCategories: any[]) => {
  if (!barGraphData) return {};

  return barGraphData.map((graphData: any) => {
    let newGraphData = { ...graphData, marker: { ...graphData.marker } };

    if (newGraphData.customdata[0][0] === GRAPH_ENTITIES.FDA_APPROVAL) {
      const meetingsData = newGraphData.customdata[0][6];
      const filteredMeetingData = filterMeetingsByTypeAndCategory(meetingsData, currentSelectedCategories);
      newGraphData.customdata[0][7] = filteredMeetingData;
    } else if (newGraphData.customdata[0][0] === GRAPH_ENTITIES.REG_INFO_MEETING) {
      const filteredMeetingData = getFilteredMeetings(newGraphData.customdata[0][2], currentSelectedCategories);
      if (filteredMeetingData.length > 0) {
        newGraphData.customdata[0][1] = filteredMeetingData[0];
        newGraphData.marker.opacity = 1;
        newGraphData.mode = 'markers+text';

        const additionalMeetingCount = filteredMeetingData.length - 1;
        const meetingMarkertext = additionalMeetingCount
          ? `${getMeetingMarkerText(filteredMeetingData[0])}<br>+${additionalMeetingCount}`
          : getMeetingMarkerText(filteredMeetingData[0]);

        newGraphData.text = meetingMarkertext;
        newGraphData.hovertemplate = filteredMeetingData.map((data: any) => `${data.type} ${data.category}`).join(', ');
      } else {
        newGraphData.marker.opacity = 0;
        newGraphData.mode = 'markers';
      }
    }

    return newGraphData;
  });
};

export const processRegulatoryInfoMeetings = (meetingData: any) => {
  const shapes: Array<any> = [];
  const trialReviewBars: Array<any> = [];

  if (!isEmpty(meetingData)) {
    const groupedMeetings = groupBy(meetingData, (meeting: any) => {
      const date = new Date(meeting.start_date);
      return `${date.getFullYear()}-${date.getMonth() + 1}`; // Group by "YYYY-MM" format
    });

    // Add horizontal line at y=0 on y2 axis
    const createLine = (yPosition: number, color: string) => ({
      type: 'line',
      xref: 'paper',
      yref: 'y2',
      x0: 0,
      x1: 1,
      y0: yPosition,
      y1: yPosition,
      line: {
        color,
        width: 1,
        dash: 'dash',
      },
    });

    shapes.push(createLine(-2, '#616161'));
    shapes.push(createLine(-5, 'white'));

    // Loop through the grouped meetings (one marker per month)
    Object.keys(groupedMeetings).forEach((groupKey) => {
      const meetingsInMonth = groupedMeetings[groupKey];
      const firstMeeting = meetingsInMonth[0]; // Pick the first meeting in the group
      const { start_date: startDate, category } = firstMeeting;

      const additionalMeetingCount = meetingsInMonth.length - 1;
      const meetingMarkertext = additionalMeetingCount
        ? `${getMeetingMarkerText(firstMeeting)}<br>+${additionalMeetingCount}<br>`
        : getMeetingMarkerText(firstMeeting);

      const hovertemplate = meetingsInMonth.map((data) => `${data.type} ${data.category}`).join(', ');
      trialReviewBars.push({
        ...meetingDataConfig,
        name: category,
        base: [startDate],
        mode: 'markers+text',
        startDate,
        x: [startDate],
        y: [-2],
        hovertemplate,
        text: meetingMarkertext,
        textposition: 'bottom center',
        textfont_color: '#616161',
        customdata: [[GRAPH_ENTITIES.REG_INFO_MEETING, firstMeeting, meetingsInMonth]],
      });
    });
  }

  return { shapes, trialReviewBars };
};
