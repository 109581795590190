import React, { useEffect, useState,useMemo } from 'react';
import { Collapse, Divider, List, ListItemButton, ListItemText, Box } from '@mui/material';
import { styles } from '../../styles/MeetingList';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
interface Meeting {
  type: string;
  category: string;
}

interface MeetingData {
  [year: string]: {
    [month: string]: Meeting[];
  };
}

interface MeetingListProps {
  meetingData: MeetingData;
  setSelectedMeeting: (meeting: { month: string; year: string; index: number }) => void;
  setMenuAnchorEl: (el: null) => void;
  setHighlightedMeeting: (meeting: { month: string; year: string; idx: number }) => void;
}

const MeetingList: React.FC<MeetingListProps> = ({
  meetingData,
  setSelectedMeeting,
  setMenuAnchorEl,
  setHighlightedMeeting,
}) => {
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});

  const initialOpenItems = useMemo(() => {
    const computedItems: { [key: string]: boolean } = {};
    Object.entries(meetingData).forEach(([year, months]) => {
      Object.entries(months).forEach(([month]) => {
        computedItems[`${month}-${year}`] = true;
      });
    });
    return computedItems;
  }, [meetingData]);
  
  useEffect(() => {
    setOpenItems(initialOpenItems);
  }, [initialOpenItems]);

  const handleClick = (key: string) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleMeetingClick = (month: string, year: string, index: number) => {
    setSelectedMeeting({ month, year, index });
    setMenuAnchorEl(null);
    setHighlightedMeeting({ month, year, idx: index });
  };

  return (
    <List sx={styles.list}>
      {Object.entries(meetingData).map(([year, months]) =>
        Object.entries(months).map(([month, meetings]) => {
          const key = `${month}-${year}`;
          return (
            <div key={key}>
              <ListItemButton onClick={() => handleClick(key)} sx={styles.listItemButton}>
                <ListItemText disableTypography primary={`${month} ${year}`} sx={styles.listItemText} />
                {openItems[key] ? <ArrowDropDownIcon sx={styles.dropDownIcon}/> : <ArrowDropUpIcon sx={styles.dropDownIcon} />}
              </ListItemButton>
              <Collapse in={openItems[key]} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <Box sx={styles.collapseBox}>
                    <Divider orientation='vertical' flexItem sx={styles.verticalDivider} />
                    <Box sx={styles.meetingBox}>
                      {meetings.map((meeting, idx) => (
                        <Box key={idx} sx={styles.meetingBoxItem}>
                          <ListItemButton
                            sx={styles.meetingListItemButton}
                            onClick={() => handleMeetingClick(month, year, idx)}
                            disableGutters
                          >
                            <ListItemText
                              disableTypography
                              primary={`${meeting.type} : ${meeting.category}`}
                              sx={styles.meetingText}
                            />
                          </ListItemButton>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </List>
              </Collapse>
            </div>
          );
        })
      )}
    </List>
  );
};

export default React.memo(MeetingList);
